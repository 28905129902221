




import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'ClientMessagesPage',
});
