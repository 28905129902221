import { render, staticRenderFns } from "./ClientMessagesPage.vue?vue&type=template&id=3f3b2627&scoped=true&"
import script from "./ClientMessagesPage.vue?vue&type=script&lang=ts&"
export * from "./ClientMessagesPage.vue?vue&type=script&lang=ts&"
import style0 from "./ClientMessagesPage.vue?vue&type=style&index=0&id=3f3b2627&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3b2627",
  null
  
)

export default component.exports